const words = [
  "abandon",
  "ability",
  "able",
  "about",
  "above",
  "abroad",
  "absence",
  "absolute",
  "absorb",
  "abstract",
  "absurd",
  "abundance",
  "academic",
  "accelerate",
  "accent",
  "accept",
  "access",
  "accident",
  "acclaim",
  "accompany",
  "accomplish",
  "according",
  "account",
  "accurate",
  "accuse",
  "achieve",
  "acid",
  "acknowledge",
  "acquire",
  "across",
  "act",
  "action",
  "active",
  "activist",
  "activity",
  "actor",
  "actress",
  "actual",
  "acute",
  "adapt",
  "add",
  "addition",
  "address",
  "adhere",
  "adjacent",
  "adjust",
  "administer",
  "admiration",
  "admit",
  "adopt",
];

export default words;
